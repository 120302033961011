import React from "react";
import Layout from "../components/layout-contents";
import styles from "../styles/music.module.css";
import Container from "../components/container";
import { graphql } from "gatsby";

const User = props => (
  <div className={styles.about}>
    <div className={styles.description}>
      <h2 className={styles.title}>{props.title}</h2>
      <audio controls controlsList="nodownload" >
        <source src={props.audio} />
        Your browser does not support the audio element.
      </audio>
      <h3 className={styles.title}>{props.subtitle}</h3>
    </div>
    <img src={props.image} className={styles.image} alt="" />
  </div>
);

/*
  <h1>About CSS Modules</h1>
    <p>CSS Modules are cool</p>
*/
export default ({ data }) => (
  <Layout>
    <Container>
    <h1 style={{ fontSize: 'calc(1.5rem + 1.5vmin)', textAlign: 'center' }}>MUSIC</h1>
      {data.allMusicJson.edges.map(({ node }) => (
        <User
          title={node.title}
          subtitle={node.subtitle}
          excerpt={node.excerpt}
          image={node.image}
          audio={node.audio}
        />
      ))}
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    allMusicJson {
      edges {
        node {
          title
          subtitle
          excerpt
          image
          audio
        }
      }
    }
  }
`;
